import {useNavigate, useParams,} from "react-router-dom";
import {useWarrantyCase} from "@/api/hooks/useWarrantyCase.ts";
import {ArrowDownTrayIcon, ChevronLeftIcon, PaperClipIcon} from "@heroicons/react/24/outline";
import {ChatMessage, ChatMessageHolder, ChatMessageType} from "@/components/common/ChatMessage.tsx";
import {useHousehold} from "@/api/hooks/useHousehold.ts";
import {useCallback, useMemo, useRef, useState} from "react";
import {sendFiles, sendMessage, updateStatus, WarrantyCaseMessageType, WarrantyCaseStatus} from "@/api/rest/warrantyApi.ts";
import {PaperAirplaneIcon} from "@heroicons/react/24/solid";
import {Banner} from "@/components/common/Banner.tsx";
import {useAccount} from "@/api/hooks/useAccount.ts";
import {clsx} from "clsx";
import {ApiResponse} from "@/api/rest/api.ts";
import UploadFilesPopup from "@/components/UploadFilesPopup.tsx";
import {downloadDocument2, getDocumentUrl2} from "@/api/rest/fileApi.ts";
import {useTranslation} from "react-i18next";

export default function WarrantyCasePage() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {warrantyCaseId} = useParams<{ warrantyCaseId: string }>()
    const [warrantyCase, refreshCase] = useWarrantyCase(Number(warrantyCaseId));
    const [householdData, ] = useHousehold(warrantyCase?.case.householdId ? Number(warrantyCase?.case.householdId) : null);
    const account = useAccount();

    console.log(warrantyCase)
    const availableAccounts = useMemo(() => {
        if (!householdData || !account) {
            return [];
        }
        const accountsInHousehold = householdData.accounts.map((a) => a.id);
        return account?.accounts.filter((a) => accountsInHousehold.includes(a.id)) ?? [];
    }, [householdData, account]);
    const amIBuildingOwner = useMemo(() => { return householdData?.building.accountId == availableAccounts[0]?.id }, [householdData, availableAccounts]);

    const messageContent = useRef<HTMLTextAreaElement>(null);

    const submitMessage = useCallback(async () => {
        const message = messageContent.current?.value ?? "";
        if (!message || !warrantyCaseId || !availableAccounts || availableAccounts.length < 1) {
            return;
        }
        const reponse = await sendMessage(Number(warrantyCaseId), {
            message: message,
            accountId: availableAccounts[0].id,
        })
        if (reponse.success) {
            messageContent.current!.value = "";
            await refreshCase();
        }
    }, [warrantyCaseId, availableAccounts, refreshCase]);

    const [pendingFiles, setPendingFiles] = useState<File[]>([]);
    const addFiles = useCallback(async (files: File[]): Promise<ApiResponse<unknown>> => {
        setPendingFiles([...pendingFiles, ...files]);
        return Promise.resolve({success: true, result: null});
    }, [pendingFiles]);
    const submitFiles = useCallback(async () => {
        if (!pendingFiles || !availableAccounts || availableAccounts.length < 1) {
            return;
        }
        const response = await sendFiles(Number(warrantyCaseId), availableAccounts[0].id, pendingFiles)
        if (response.success) {
            setPendingFiles([]);
            await refreshCase();
            // TODO: scroll to bottom
        }
    }, [pendingFiles, warrantyCaseId, availableAccounts, refreshCase]);

    const phoneNumber = useMemo(() => {
        if (warrantyCase?.messages && warrantyCase?.messages.length >= 2) {
            return warrantyCase?.messages[1].content.split(":")[1] ? warrantyCase?.messages[1].content.split(":")[1].trim() : "";
        }
        return null;
    }, [warrantyCase])

    const handleChangeStatus = useCallback(async (status: WarrantyCaseStatus) => {
        if (!warrantyCaseId) return;
        const response = await updateStatus(Number(warrantyCaseId), { buildingId: householdData?.building.id!, status: status });
        if (response.success) {
            await refreshCase();
        }
    }, [warrantyCaseId, householdData, refreshCase]);

    if (!warrantyCase || !householdData || !account) return (<div>Loading...</div>);
    return (
        <main
            className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
            <div className="flex flex-grow self-stretch flex-col">
                <div className="flex flex-row">
                    <ChevronLeftIcon
                        className="h-9 w-9 mr-2 hover:bg-slate-300 focus-visible:bg-dobu-orange cursor-pointer rounded"
                        onClick={() => navigate(-1)}/>
                    <h2 className="text-3xl font-bold text-center mr-4">Garantiijuhtum - {warrantyCase.case.title}</h2>
                    {amIBuildingOwner && <div className="flex flex-row gap-2">
                        {warrantyCase.case.status != WarrantyCaseStatus.CLOSED &&
                            (warrantyCase.case.status == WarrantyCaseStatus.SOLVED ?
                                <button className="rounded-md py-2 px-4" onClick={() => handleChangeStatus(WarrantyCaseStatus.RESPONSE_RECEIVED)}>Ava juhtum</button> :
                                <button className="rounded-md !bg-green-500 hover:!bg-green-600 py-2 px-4" onClick={() => handleChangeStatus(WarrantyCaseStatus.SOLVED)}>Märgi lahendatuks</button>
                        )}
                        {warrantyCase.case.status == WarrantyCaseStatus.CLOSED ?
                            <button className="rounded-md py-2 px-4" onClick={() => handleChangeStatus(WarrantyCaseStatus.QUESTION_SENT)}>Taasava juhtum</button> :
                            <button className="rounded-md !bg-red-700 hover:!bg-red-800 py-2 px-4" onClick={() => handleChangeStatus(WarrantyCaseStatus.CLOSED)}>Sulge juhtum</button>
                        }
                    </div>}
                </div>
                {amIBuildingOwner && <div className="flex flex-col ml-12 mt-3">
                    <h2 className="text-xl"><strong>Aadress: </strong>{householdData.building.street} - {householdData.household.title}</h2>
                    <h2 className="text-xl"><strong>Nimi: </strong>{householdData.accounts.find((a) => a.id === warrantyCase.messages[0].accountId)?.name}</h2>
                    {phoneNumber && <h2 className="text-xl"><strong>Telefoninumber: </strong>{phoneNumber}</h2>}
                </div>}
                <div className="flex flex-col xl:px-[20%]">
                    <ChatMessageHolder>
                        {warrantyCase.messages.map((message) => {
                            const isBuilingOwnerMessage = message.accountId == householdData.building.accountId;
                            const isMyMessage = availableAccounts.map((a) => a.id).includes(message.accountId);
                            const messageAccount = householdData.accounts.find((a) => a.id === message.accountId);
                            const messageAccountName = isBuilingOwnerMessage ? t("warranty.afterSalesService") : messageAccount?.name;
                            const displayOnRight = (isBuilingOwnerMessage && amIBuildingOwner) || isMyMessage
                            if (message.type == WarrantyCaseMessageType.AI_HISTORY && !amIBuildingOwner) {
                                return <span key={message.id} />
                            }
                            return (
                                <ChatMessage key={message.id} type={displayOnRight ? ChatMessageType.QUESTION : ChatMessageType.RESPONSE} colorType={isMyMessage ? ChatMessageType.QUESTION : ChatMessageType.RESPONSE}>
                                    <div className="flex flex-col">
                                        {message.type != WarrantyCaseMessageType.AI_HISTORY && <span className={clsx("text-sm text-gray-500", displayOnRight  && "self-end")}>{messageAccountName} - {message.createdAtTimestamp.format("LL")}</span>}
                                        {message.type == WarrantyCaseMessageType.AI_HISTORY && <span className={clsx("text-sm text-gray-500", displayOnRight  && "self-end")}>Eelnev vestlus juturobotiga</span>}
                                        {message.type == WarrantyCaseMessageType.TEXT && <span className={clsx(displayOnRight && "self-end", "whitespace-pre-wrap")}>{message.content}</span>}
                                        {message.type == WarrantyCaseMessageType.AI_HISTORY && <span className={clsx(displayOnRight && "self-end", "whitespace-pre-wrap text-gray-500")}>{message.content}</span>}
                                        {message.type == WarrantyCaseMessageType.IMAGE && <img src={getDocumentUrl2(null, true, message.content)} alt="Sõnum pildiga" className="max-w-[300px] max-h-[300px] md:max-w-[40vw] md:max-h-[40vh] object-contain m-0 rounded" />}
                                        {(message.type == WarrantyCaseMessageType.FILE || message.type == WarrantyCaseMessageType.IMAGE) && <span className={clsx("bg-slate-300 hover:bg-slate-400 cursor-pointer p-2 rounded", displayOnRight && "ss")} onClick={() => downloadDocument2(getDocumentUrl2(null, true, message.content), null)}><ArrowDownTrayIcon className="w-4 h-4 -mt-0.5 inline mx-2" />Laadi faili alla</span>}
                                    </div>
                                </ChatMessage>
                        )})}
                    </ChatMessageHolder>
                </div>
                <div className="flex-grow" />
                <div className="flex flex-col xl:px-[20%]">
                    {(warrantyCase.case.status == WarrantyCaseStatus.QUESTION_SENT || warrantyCase.case.status == WarrantyCaseStatus.RESPONSE_RECEIVED) &&
                        <label className="flex flex-grow relative">
                            <span className="mr-2 mb-2 absolute right-0 bottom-0 flex flex-row">
                                <UploadFilesPopup className="flex flex-row" uploadCallback={addFiles}>
                                    <PaperClipIcon
                                        className="w-6 h-6 inline text-gray-300 hover:text-dobu-orange cursor-pointer"/>
                                </UploadFilesPopup>
                                <PaperAirplaneIcon
                                    className="w-6 h-6 inline text-gray-300 hover:text-dobu-orange cursor-pointer" onClick={pendingFiles.length > 0 ? submitFiles : submitMessage}/>
                            </span>
                            {pendingFiles.length == 0 && <textarea className="flex-grow border border-gray-300 rounded-md p-2 scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 whitespace-pre-wrap" placeholder={amIBuildingOwner ? "Saada sõnum koduomanikule" : "Saada sõnum arendajale"} rows={5} ref={messageContent}/>}
                            {pendingFiles.length > 0 && <div className="flex flex-grow border border-gray-300 rounded-md p-2 bg-white gap-2">
                                {pendingFiles.map((file, i) => <span key={i} className="rounded-md py-2 px-4 bg-gray-200"><PaperClipIcon className="w-4 h-4 inline -mt-0.5 mr-2"/> {file.name}</span>)}
                            </div>}
                        </label>
                    }
                    {warrantyCase.case.status == WarrantyCaseStatus.CLOSED && <Banner message='Juhtum on järelteeninduse poolt suletud' success={false} />}
                    {warrantyCase.case.status == WarrantyCaseStatus.SOLVED && <Banner message='Juhtum on märgitud lahendatuks' success={true} />}
                </div>
            </div>
        </main>
    )
}
